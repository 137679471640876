<template>
  <div>
    <Navigation />

    <Hero 
        title="Nos" 
        blueTitle="Contacts"  
        ComponentTitle="Contacts"
        subTitle="Besoin d'une information ?"
        />

    <section class="p-0">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 text-center">
            <div class="p-5 my-lg-5"><i class="fas fa-3x mb-4 fa-map-marked-alt text-primary"></i>
              <h3 class="h5 text-uppercase mb-4">Permanence</h3>
              <p class="text-muted">2, rue Delamart MOUKONDO Brazzaville</p>
            </div>
          </div>
          <div class="col-lg-4 text-center bg-primary text-white">
            <div class="p-5 my-lg-5"><i class="fas fa-phone fa-3x mb-4 text-primar"></i>
              <h3 class="h5 text-uppercase mb-4">Téléphone</h3>
              <p class="fw-bold">+242 06 828 9050</p>
            </div>
          </div>
          <div class="col-lg-4 text-center">
            <div class="p-5 my-lg-5"><i class="fas fa-3x mb-4 fa-envelope text-primary"></i>
              <h3 class="h5 text-uppercase mb-4">Email</h3>
              <p class="text-muted">info@asla-oph.net</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-light">
      <div class="container">
        <div class="row align-items-center gy-5">
          <div class="col-lg-7">
            <div class="card border-0 shadow-sm">
              <div class="card-body p-5 border-top border-primary border-2">
                <!-- <h2 class=""><span class="text-primary lined">Laissez un message</span></h2> -->
                <!-- <p class="text-muted mb-4">Nulla tristique ipsum in quam. Integer ac elit. Duis turpis faucibus non</p> -->
                <!-- <form class="make-appointment-form" action="!#">
                  <div class="row gy-4">
                    <div class="col-lg-6">
                      <input class="form-control" type="text" name="firstName" placeholder="Votre nom">
                    </div>

                    <div class="col-lg-6">
                      <input class="form-control" type="email" name="email" placeholder="Votre adresse mail">
                    </div>
                    <div class="col-lg-12">
                      <input class="form-control" type="text" name="phone" placeholder="Objet du message">
                    </div>
                    
                    <div class="col-lg-12">
                      <textarea class="form-control" name="message" rows="5" placeholder="Corps du message"></textarea>
                    </div>
                    <div class="col-lg-12">
                      <button class="btn btn-primary w-100" type="button">Envoyer le message</button>
                    </div>
                  </div>
                </form> -->
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3978.9517100171256!2d15.264793273139256!3d-4.2296724459237325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a6bcdd412eb9bd7%3A0xf60e9fa91b941225!2sRTA-OPH%20MONDE!5e0!3m2!1sfr!2scg!4v1741168087894!5m2!1sfr!2scg" width="100%" height="280" style="border:0;" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>

          <div class="col-lg-5">
            <img src="../../assets/img/insigne.jpg" class="img-thumbnail" width="90%" alt="Association louzolo Amour OPH">
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Navigation from "../Navigation.vue";
import Footer from "../Footer.vue";
import Hero from "../Hero.vue";
export default {
  components: {
    Navigation,
    Footer,
    Hero
  },
};
</script>

<style scoped>
.bg-img {
  background-image: url("../../assets/img/banniere5.jpg");
}
</style>