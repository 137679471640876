import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./axios/index";

import timeago from 'vue-timeago3'
import { fr } from 'date-fns/locale'

// import VueMeta from 'vue-meta'

// Vue.use(VueMeta)

const timeagoOptions = {
    converterOptions: {
        includeSeconds: true,
    },
    locale: fr,
  }

// import "../public/assets/vendor/jquery/jquery.min.js"
import "../public/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"
import "../public/assets/js/front.js"

import "../public/assets/css/style.default.css"

// import {createHead} from '@vueuse/head'
// import { createHead } from '@unhead/vue'

// const head = createHead()
// app.use(head)

createApp(App)
.use(store)
.use(router)
.use(timeago, timeagoOptions)
// .use(createHead())
// .use(VueMeta)
.mount('#app')